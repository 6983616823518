@import "../../../style/design-tokens.module";

.lecture-flash-container {
  background-color: $white;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.lecture-flash-current-word {
  font-weight: 700;
  font-family: cursive;
  font-size: 100px;
  height: 200px;
}

.lecture-flash-buttons {
  position: absolute;
  bottom: 100px;
  right: 100px;

  .Mui-disabled:not(.MuiButton-outlinedPrimary) {
    background-color: $pink !important;
    color: $white !important;
  }
}
