@import "./global-tokens/palette.module";

$purple: $purple-500;
$purpleLight: $purple-300;
$purpleDark: $purple-700;

$pink: $pink-500;
$pinkLight: $pink-300;
$pinkDark: $pink-700;

$yellow: $yellow-500;

$blue: $light-blue-500;

$green: $green-500;

$black: #000000;
$white: #ffffff;

$transparent: transparent;

:export {
  purple: $purple;
  purpleLight: $purpleLight;
  purpleDark: $purpleDark;
  pink: $pink;
  pinkLight: $pinkLight;
  pinkDark: $pinkDark;
  yellow: $yellow;
  blue: $blue;
  green: $green;
  black: $black;
  white: $white;
  transparent: $transparent;
}
