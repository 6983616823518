@import "./style/design-tokens.module";

// TODO _ valeur border-radius dans une constante (utilisé aussi dans SettingsDialog)
.app {
  background-color: $white;
  border-radius: 1rem 1rem 1.5rem 1.5rem;
  min-height: 100vh;
  overflow: hidden;
  // TODO _ regler overflow et scrollbar afin de toujours voir les borders radius
  // TODO _ overflow-x: hidden; overflow-y: auto; -> la scrollbar se superpose :(

  &.is-responsive {
    border-radius: 0 0 1.5rem 1.5rem;
  }
}
